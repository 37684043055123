import * as React from 'react';
import FormWizard from './FormWizard';
import { connect, FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { MDCFormField } from '@material/form-field';
import { MDCRadio } from '@material/radio';
import { MDCTextField } from '@material/textfield';

/**
 * @class CompanyForm
 * TODO: (stefan@cropmates.com) TS typing errors, see
 * https://github.com/jaredpalmer/formik/pull/1053
 */
class CompanyForm extends React.Component<{ formik: FormikProps<any> }> {
  private company: React.RefObject<any>

  private acreageS: React.RefObject<any>

  private acreageSField: React.RefObject<any>

  private acreageM: React.RefObject<any>

  private acreageMField: React.RefObject<any>

  private acreageL: React.RefObject<any>

  private acreageLField: React.RefObject<any>

  private companyTextField: MDCTextField

  private acreageSRadio: MDCRadio

  private acreageSFormField: MDCFormField

  private acreageMRadio: MDCRadio

  private acreageMFormField: MDCFormField

  private acreageLRadio: MDCRadio

  private acreageLFormField: MDCFormField

  private errors: any

  private touched: any

  constructor(props) {
    super(props)

    this.company = React.createRef()
    this.acreageS = React.createRef()
    this.acreageSField = React.createRef()
    this.acreageM = React.createRef()
    this.acreageMField = React.createRef()
    this.acreageL = React.createRef()
    this.acreageLField = React.createRef()
    // this.telephone = React.createRef()
  }

  componentDidMount() {
    this.companyTextField = new MDCTextField(this.company.current)
    this.acreageSRadio = new MDCRadio(this.acreageS.current)
    this.acreageSFormField = new MDCFormField(this.acreageSField.current)
    this.acreageSFormField.input = this.acreageSRadio
    this.acreageMRadio = new MDCRadio(this.acreageM.current)
    this.acreageMFormField = new MDCFormField(this.acreageMField.current)
    this.acreageMFormField.input = this.acreageMRadio
    this.acreageLRadio = new MDCRadio(this.acreageL.current)
    this.acreageLFormField = new MDCFormField(this.acreageLField.current)
    this.acreageLFormField.input = this.acreageLRadio
    // this.telephoneTextField = new MDCTextField(this.telephone.current)

    this.companyTextField.useNativeValidation = false
    // this.telephoneTextField.useNativeValidation = false

    FormWizard.setFormValidity.call(this, this.errors, this.touched)
  }

  render() {
    this.errors = this.props.formik.errors
    this.touched = this.props.formik.touched
    FormWizard.setFormValidity.call(this, this.props.formik.errors, this.props.formik.touched)

    return (
      <>
        {this.props.formik.values && (
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-12">
              <h1 className="mdc-typography--headline2">
                <FormattedMessage id="signup.company.title" defaultMessage="Wunderbar, bitte verraten Sie uns etwas über Ihren Betrieb." />
              </h1>
            </div>

            {/* Company name */}
            <div className="mdc-layout-grid__cell--span-12">
              <div className="mdc-text-field mdc-text-field--outlined cm-text-field--l" id="company" ref={this.company}>
                <input
                  type="text"
                  id="company-input"
                  className="mdc-text-field__input"
                  name="user.tenants[0].name"
                  value={(this.props.formik.values.user.tenants[0] as any).name}
                  onChange={this.props.formik.handleChange}
                  onBlur={this.props.formik.handleBlur}
                />
                <div className="mdc-notched-outline">
                  <div className="mdc-notched-outline__leading"></div>
                  <div className="mdc-notched-outline__notch">
                    <label
                      htmlFor="company-input"
                      className={ 'mdc-floating-label ' + (this.props.formik.values.user.tenants[0].name ? 'mdc-floating-label--float-above' : '') }
                    >
                      <FormattedMessage id="signup.company" defaultMessage="Betriebsname" />
                    </label>
                  </div>
                  <div className="mdc-notched-outline__trailing"></div>
                </div>
              </div>
              { this.props.formik.errors.user &&
                (this.props.formik.errors.user as any).tenants &&
                (this.props.formik.errors.user as any).tenants[0] &&
                (this.props.formik.errors.user as any).tenants[0].name &&
                this.props.formik.touched.user &&
                (this.props.formik.touched.user as any).tenants &&
                (this.props.formik.touched.user as any).tenants[0] &&
                (this.props.formik.touched.user as any).tenants[0].name && (
                  <p
                    id="company-helper-text"
                    className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                    aria-hidden="true"
                  >
                  { this.props.formik.errors &&
                    (this.props.formik.errors.user as any).tenants &&
                    (this.props.formik.errors.user as any).tenants[0] &&
                    (this.props.formik.errors.user as any).tenants[0].name == 'required' && (
                      <FormattedMessage id="signup.error.tenant.name.required" defaultMessage="Der Betriebsname ist erforderlich" />
                    )}
                  </p>
                )}
            </div>

            {/* acreage */}
            <div className="mdc-layout-grid__cell--span-12">
              <span className="mdc-typography--subtitle1 cm-typography--subtitle1-form">
                <FormattedMessage id="signup.acreage" defaultMessage="Anbaufläche" />
              </span>
            </div>

            <div className="mdc-layout-grid__cell--span-12">
              {/* Small */}
              <div className="mdc-form-field" id="cm-acreage-form-field--s" ref={this.acreageSField}>
                <div className="mdc-radio" id="cm-radio-acreage--s" ref={this.acreageS}>
                  <input
                    className="mdc-radio__native-control"
                    type="radio"
                    id="small"
                    name="user.tenants[0].acreage"
                    value="1-100"
                    checked={this.props.formik.values.user.tenants[0].acreage === '1-100'}
                    onChange={this.props.formik.handleChange}
                  />
                  <div className="mdc-radio__background">
                    <div className="mdc-radio__outer-circle" />
                    <div className="mdc-radio__inner-circle" />
                  </div>
                </div>
                <label htmlFor="small">
                  <FormattedMessage id="signup.acreage-s" defaultMessage="1 - 100 Hektar" />
                </label>
              </div>

              {/* Medium */}
              <div className="mdc-form-field cm-form-field--inline" id="cm-acreage-form-field--m" ref={this.acreageMField}>
                <div className="mdc-radio" id="cm-radio-acreage--m" ref={this.acreageM}>
                  <input
                    className="mdc-radio__native-control"
                    type="radio"
                    id="medium"
                    name="user.tenants[0].acreage"
                    value="101-1000"
                    checked={this.props.formik.values.user.tenants[0].acreage === '101-1000'}
                    onChange={this.props.formik.handleChange}
                  />
                  <div className="mdc-radio__background">
                    <div className="mdc-radio__outer-circle" />
                    <div className="mdc-radio__inner-circle" />
                  </div>
                </div>
                <label htmlFor="medium">
                  <FormattedMessage id="signup.acreage-m" defaultMessage="101 - 1000 Hektar" />
                </label>
              </div>

              {/* Large */}
              <div className="mdc-form-field cm-form-field--inline" id="cm-acreage-form-field--l" ref={this.acreageLField}>
                <div className="mdc-radio" id="cm-radio-acreage--l" ref={this.acreageL}>
                  <input
                    className="mdc-radio__native-control"
                    type="radio"
                    id="large"
                    name="user.tenants[0].acreage"
                    value="1000+"
                    checked={this.props.formik.values.user.tenants[0].acreage === '1000+'}
                    onChange={this.props.formik.handleChange}
                  />
                  <div className="mdc-radio__background">
                    <div className="mdc-radio__outer-circle" />
                    <div className="mdc-radio__inner-circle" />
                  </div>
                </div>
                <label htmlFor="large">
                  <FormattedMessage id="signup.acreage-l" defaultMessage="1000+ Hektar" />
                </label>
              </div>
              { this.props.formik.errors.user &&
                (this.props.formik.errors.user as any).tenants &&
                (this.props.formik.errors.user as any).tenants[0] &&
                (this.props.formik.errors.user as any).tenants[0].acreage &&
                this.props.formik.touched.user &&
                (this.props.formik.touched.user as any).tenants &&
                (this.props.formik.touched.user as any).tenants[0] &&
                (this.props.formik.touched.user as any).tenants[0].acreage && (
                  <p
                    id="name-helper-text"
                    className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                    aria-hidden="true"
                  >
                  { this.props.formik.errors &&
                    this.props.formik.errors.user && 
                    (this.props.formik.errors.user as any).tenants && 
                    (this.props.formik.errors.user as any).tenants[0] &&
                    (this.props.formik.errors.user as any).tenants[0].acreage == 'required' && (
                      <FormattedMessage id="signup.error.tenant.acreage.required" defaultMessage="Bitte wählen Sie die Größe der Anbaufläche aus" />
                    )}
                  </p>
                )}
            </div>

            {/* Telephone number */}
            {/* <div className="mdc-layout-grid__cell--span-12">
              <div
                className="mdc-text-field mdc-text-field--outlined mdc-text-field--with-leading-icon cm-text-field--l"
                id="telephone"
                ref={this.telephone}
              >
                <i
                  className="material-icons mdc-text-field__icon"
                  tabIndex="-1"
                  role="button"
                >
                  phone
                </i>
                <input
                  type="tel"
                  id="telephone-input"
                  className="mdc-text-field__input"
                  name="user.tenants[0].telephone"
                  onChange={this.props.formik.handleChange}
                  onBlur={this.props.formik.handleBlur}
                />
                <label htmlFor="telephone-input" className="mdc-floating-label">
                  <FormattedMessage
                    id="signup.telephone"
                    defaultMessage="Telefonnummer"
                  />
                </label>
                <div className="mdc-notched-outline">
                  <svg>
                    <path className="mdc-notched-outline__path" />
                  </svg>
                </div>
                <div className="mdc-notched-outline__idle" />
              </div>
              {this.props.formik.errors.user.tenants[0] &&
                this.props.formik.errors.user.tenants[0].telephone &&
                this.props.formik.touched.user.tenants[0] &&
                this.props.formik.touched.user.tenants[0].telephone && (
                  <p
                    id="telephone-helper-text"
                    className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                    aria-hidden="true"
                  >
                    {this.props.formik.errors.user.tenants[0].telephone == 'required' && (
                      <FormattedMessage
                        id="signup.error.tenant.telephone.required"
                        defaultMessage="Ihre Telefonnummer ist erforderlich"
                      />
                    )}
                    {this.props.formik.errors.user.tenants[0].telephone == 'invalid' && (
                      <FormattedMessage
                        id="signup.error.tenant.telephone.invalid"
                        defaultMessage="Bitte geben Sie eine gültige Telefonnummer an"
                      />
                    )}
                  </p>
                )}
            </div> */}
          </div>
        )}
      </>
    )
  }
}

export default connect(CompanyForm as React.ComponentType<any>)
